.form-action-button-submit {
    display: flex;
    align-items: center;
    border: none;
    background-color: #4F46E5;
    padding: 10px 19px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
}

.form-button-container {
    display: flex;
    justify-content: flex-end;
}

.form-action-button {
    display: flex;
    align-items: center;
    border: none;
    background-color: #FF0000;
    border: 1px solid rgba(40, 45, 38, 0.05);
    color: #FFFFFF;
    padding: 10px 19px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}